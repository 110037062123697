@use '@angular/material' as mat;
// @use '@matheo/datepicker/theming' as datepicker;
@use './mateo-datepiker.scss' as datepicker;
@import './custom-theme.scss';

/* THEME */
// Material theming tools
// @import '@angular/material/theming';

// Import app and page component themes here to enable theming for them
// @import "src/app/layout/components/toolbar/toolbar.theme";

// https://github.com/angular/components/issues/5648

@import "../@fuse/scss/theme";

@import './fonts.scss';

// -----------------------------------------------------------------------------------------------------
// @ Component theming
// -----------------------------------------------------------------------------------------------------
// @mixin components-typographies($typography) {
//   @include mat.button-toggle-typography($typography);
//   @include mat.fab-typography($typography);
//   @include mat.autocomplete-typography($typography);
//   @include mat.bottom-sheet-typography($typography);
//   @include mat.checkbox-typography($typography);
//   @include mat.chips-typography($typography);
//   @include mat.table-typography($typography);
//   @include mat.dialog-typography($typography);
//   @include mat.divider-typography($typography);
//   @include mat.expansion-typography($typography);
//   @include mat.form-field-typography($typography);
//   @include mat.grid-list-typography($typography);
//   @include mat.input-typography($typography);
//   @include mat.list-typography($typography);
//   @include mat.menu-typography($typography);
//   @include mat.paginator-typography($typography);
//   @include mat.progress-bar-typography($typography);
//   @include mat.progress-spinner-typography($typography);
//   @include mat.radio-typography($typography);
//   @include mat.select-typography($typography);
//   @include mat.sidenav-typography($typography);
//   @include mat.slide-toggle-typography($thetypographyme);
//   @include mat.slider-typography($typography);
//   @include mat.stepper-typography($typography);
//   @include mat.sort-typography($typography);
//   @include mat.toolbar-typography($typography);
//   @include mat.tooltip-typography($typography);
//   @include mat.tree-typography($typography);
//   @include mat.snack-bar-typography($typography);
//   @include datepicker.typography($typography);
// }
// Define a mixin for easier access
@mixin components-theme($theme) {
  // https://github.com/angular/components/blob/master/src/material/core/theming/_all-theme.scss
  @include mat.button-toggle-theme($theme);
  @include mat.fab-theme($theme);
  @include mat.autocomplete-theme($theme);
  @include mat.bottom-sheet-theme($theme);
  @include mat.checkbox-theme($theme);
  @include mat.chips-theme($theme);
  @include mat.table-theme($theme);
  @include mat.dialog-theme($theme);
  @include mat.divider-theme($theme);
  @include mat.expansion-theme($theme);
  @include mat.form-field-theme($theme);
  @include mat.grid-list-theme($theme);
  @include mat.input-theme($theme);
  @include mat.list-theme($theme);
  @include mat.menu-theme($theme);
  @include mat.paginator-theme($theme);
  @include mat.progress-bar-theme($theme);
  @include mat.progress-spinner-theme($theme);
  @include mat.radio-theme($theme);
  @include mat.select-theme($theme);
  @include mat.sidenav-theme($theme);
  @include mat.slide-toggle-theme($theme);
  @include mat.slider-theme($theme);
  @include mat.stepper-theme($theme);
  @include mat.sort-theme($theme);
  @include mat.toolbar-theme($theme);
  @include mat.tooltip-theme($theme);
  @include mat.tree-theme($theme);
  @include mat.snack-bar-theme($theme);
  @include datepicker.theme($theme);
}




body {
  @include fuse-core-theme($theme);
  // $palettes: (primary: $primary-palette,
  // accent: $accent-palette,
  // warn: $warn-palette);

  // @include fuse-color-classes($palettes);




  // Create an Angular Material theme from the $theme map
  // Apply the theme to the user components
  @include components-theme($theme);
  // overrides mat-date-picker angular 15 (migrazione)
  // vediamo se aggiorna matheo-date-picker
  .mat-datepicker-toggle > button {
    padding: 12px;
  }

  .mat-calendar {
    --mdc-typography-button-font-weight: 400;
    .mat-custom-time-minute {
      font-size: 42px;
    }
    .mdc-button__label {
      white-space: nowrap;
      font-size: inherit;
    }
    .mat-calendar-previous-button , .mat-calendar-next-button {
      padding: 0;
      min-width: 0;
      width: 40px;
      height: 40px;
      flex-shrink: 0;
      line-height: 40px;
      border-radius: 50%;
    }
  }




}
