@use '@angular/material' as mat;
@import './material-theme';
@import './custom-theme.scss';
/* THEME */
// Material theming tools
// @import '@angular/material/core/theming';


/* This injects Tailwind's base styles and any base styles registered by plugins. */
@tailwind base;


/* This injects additional styles into Tailwind's base styles layer. */
// @layer   {

// }

/* This injects Tailwind's component classes and any component classes registered by plugins. */
@tailwind components;
@tailwind utilities;

/* MATERIAL CORE */
@include mat-core();
/* MATERIAL TYPOGRAPHIES */
// @include components-typographies($typography);
/* MATERIAL THEMES */
@include components-theme($theme);



* {
  /* Text rendering */
  text-rendering: optimizeLegibility;
  -o-text-rendering: optimizeLegibility;
  -ms-text-rendering: optimizeLegibility;
  -moz-text-rendering: optimizeLegibility;
  -webkit-text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;

  /* Remove the focus ring */
  &:focus {
    outline: none !important;
  }


}


html {
  line-height: 1.4;
  font-feature-settings: 'salt';
  font-family: 'Roboto', 'Helvetica Neue', 'Arial', sans-serif;

  ::-webkit-scrollbar {
    width: 5px;
    height: 100%;
  }
    
  ::-webkit-scrollbar-thumb {
    background: rgba(221, 25, 24, 0.7) !important;  
    border-radius: 10px;
  }
}

/* Better spacing and border for horizontal rule */
hr {
  margin: 32px 0;
  border-bottom-width: 1px;
}

/* Make images and videos to take up all the available space */
img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

/* Fix: Disabled placeholder color is too faded on Safari */
// input[disabled] {
//   opacity: 1;
//   -webkit-text-fill-color: currentColor;
// }

/* Print styles */
@media print {

  /* Make the base font size smaller for print so everything is scaled nicely */
  html {
    font-size: 12px !important;
  }
}
/* OVERRIDES */
.tox-tinymce-aux {
  z-index: 900 !important;
}

body.mce-content-body > p {
  padding: 10px;
}

.mat-tooltip {
  font-size: 12px !important;
  @apply bg-primary-500 #{!important};
}


mat-form-field * {
  border-style: none;
}

button.mat-button-base > .mat-button-wrapper {
  vertical-align: middle;
}

.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: inherit;
}

.mat-mdc-button:not(:disabled) {
  --mdc-text-button-label-text-color: none;
}


@import url('./app-ui.scss');
