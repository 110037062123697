@use '@angular/material' as mat;

@import './custom-theme.scss';

/* THEME */
// Material theming tools
// @import '@angular/material/core/theming';



@mixin mat-core() {
  @include mat.core();
}



// Setup the typography
// @include mat.all-component-typographies($typography);

// @mixin components-typographies($typography) {
//   @include mat.typography-hierarchy($typography);
//   @include mat.badge-typography($typography);
//   @include mat.button-typography($theme);
//   @include mat.card-typography($typography);
//   @include mat.checkbox-typography($typography);
//   @include mat.icon-typography($typography);
//   // @include mat.tabs-typography($typography);
// }

// -----------------------------------------------------------------------------------------------------
// @ Component theming
// -----------------------------------------------------------------------------------------------------

// Define a mixin for easier access
@mixin components-theme($theme) {
  // https://github.com/angular/components/blob/master/src/material/core/theming/_all-theme.scss
  @include mat.core-theme($theme);
  @include mat.badge-theme($theme);
  @include mat.button-theme($theme);
  @include mat.icon-button-theme($theme);
  @include mat.card-theme($theme);
  @include mat.checkbox-theme($theme);
  @include mat.icon-theme($theme);
  @include mat.tabs-theme($navbar-theme);
}

